import React, { useEffect } from 'react';
import {Adsense} from '@ctrl/react-adsense';

function GoogleAdsenseBoxDisplay() {

    useEffect(() => {
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${process.env.REACT_APP_ADS_GOOGLE_ADSENSE_SCRIPT_CLIENT_ID}`;
        script.crossOrigin = 'anonymous';
    
        document.body.appendChild(script);
    
        return () => {
          // Remover el script cuando el componente se desmonte
          document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        // Verifica si el script de adsbygoogle está presente
        if (window.adsbygoogle) {
          try {
            // Empuja un nuevo anuncio al arreglo adsbygoogle
            (window.adsbygoogle = window.adsbygoogle || []).push({});
          } catch (e) {
            console.error('Error al cargar el anuncio:', e);
          }
        }
    }, []);

    return (
        <div className={'card flex-column p-1 m-2 position-relative w-100-mb'}
             style={{display: process.env.REACT_APP_ADS_GOOGLE_ADSENSE === 'active' ? 'flex' : 'none'}}>
            {/*
            <script async 
                src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${process.env.REACT_APP_ADS_GOOGLE_ADSENSE_SCRIPT_CLIENT_ID}`}
                crossorigin="anonymous"></script>*/}
            <Adsense
                client={process.env.REACT_APP_ADS_GOOGLE_ADSENSE_SCRIPT_CLIENT_ID}
                slot={process.env.REACT_APP_ADS_GOOGLE_ADSENSE_SCRIPT_CLIENT_SLOT}
                style={{ display: 'block' }}
                layoutKey='-81+eo+1+2-2'
                format="fluid"
                adTest='on'
            />
            {/*
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
            */}
        </div>
    );
}

export default GoogleAdsenseBoxDisplay;